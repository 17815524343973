const initialState = {
    data: [],
    total: 1,
    params: {},
    schemes: [],
    proposals: [],
    circularsData: [],
    selectedApplicationData: [],
    headsData : []
}

const landDefermentReducer = (state = initialState, action) => {
    switch (action.type) {

        case 'GET_DATA_DEMAND_NOTE':
            return {
                ...state,
                allData: action.allData,
                data: action.data,
                total: action.totalPages,
                params: action.params
            }

        case 'CREATE_CIRCULAR':
            return { ...state }

        case 'UPDATE_CIRCULAR':
            return { ...state }

        case 'SET_SCHEMES_BY_DEVELOPER':
            return {
                ...state,
                schemes: action.payload,
            };
        case 'SET_PROPOSAL_BY_DEVELOPER':
            return {
                ...state,
                proposals: action.payload,
            };

        case 'GET_CIRCULAR_DATA':
            return {
                ...state,
                circularsData: action.data,
            };
        case 'GET_APPLICATION_DATA':
            return {
                ...state,
                selectedApplicationData: action.data,
            };
            case 'GET_HEAD_DATA':
            return {
                ...state,
                headsData: action.data,
            };

        default:
            return { ...state }
    }
}
export default landDefermentReducer

const initialState = {
    data: [],
    total: 1,
    params: {},
    applicantData: {},
    letterData: {},
    inboxNotificationData: [],
    dNHearingData: [],
    fAHearingData: [],
    activityLogList: [],
}

const LandAcquisitionReducer = (state = initialState, action) => {
    switch (action.type) {

        case 'LA_APPLICATIONS_LIST':
            return {
                ...state,
                allData: action.allData,
                data: action.data,
                total: action.totalPages,
                params: action.params
            }

        case "GET_LA_APPLICATION_DATA":
            return {
                ...state,
                applicantData: action.data,
            };

        case "GET_WARD_DATA":
            return {
                ...state,
                activeWardData: action.data,
            };

        case "GET_LA_LETTER_DATA":
            return {
                ...state,
                letterData: action.data,
            }

        case "LA_INBOX_NOTIFICATION_LIST":
            return {
                ...state,
                inboxNotificationData: action.data,
            }

        case "GET_DN_HEARINGS":
            return {
                ...state,
                dNHearingData: action?.data,
            }

        case "GET_FA_HEARINGS":
            return {
                ...state,
                fAHearingData: action?.data,
            }

        case 'GET_LA_ACTIVITY':
            return {
                ...state,
                activityLogList: action.data,
            }

        default:
            return { ...state }
    }
}
export default LandAcquisitionReducer

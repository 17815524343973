import { useEffect } from "react";
import firebase from "@firebase/app";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

const MySwal = withReactContent(Swal);

const useIdleLogout = (timeout = 2 * 60 * 60 * 1000) => {
  useEffect(() => {
    let timer;

    const isUserLoggedIn = async () => {
      const userId = localStorage.getItem("user_id");
      return !!userId;
    };

    const resetTimer = () => {
      if (timer) clearTimeout(timer);
      timer = setTimeout(() => {
        handleLogout();
      }, timeout);
    };

    const handleLogout = () => {
      MySwal.fire({
        title: "Session Expired",
        text: "You have been logged out due to inactivity.",
        icon: "warning",
        confirmButtonText: "OK",
        allowOutsideClick: false, // Prevent closing by clicking outside
      }).then(() => {
        localStorage.clear();
        firebase.auth().signOut().then(() => {
          window.location.href = "/signin"; // Redirect to login page
        });
      });
    };

    const setupIdleTimeout = async () => {
      const loggedIn = await isUserLoggedIn();
     
      if (!loggedIn) return; // Stop if the user is not logged in

      const events = [
        "mousemove",    // Triggered when the mouse moves
        "keydown",      // Fires when a key is pressed
        "scroll",       // Triggered when the user scrolls
        "click",        // Fires on mouse click or tap
        "resize",       // Triggered when the window is resized
        "touchstart",   // Fires when touch starts on screen
        "touchmove",    // Triggered when touch moves on screen
        "touchend",     // Fires when touch ends on screen
        "pointermove",  // Triggered when any pointer (mouse, touch, etc.) moves
        "pointerdown",  // Fires when any pointer is pressed
        "pointerup",    // Triggered when any pointer is released
        "wheel",        // Fires on mouse wheel or track pad scroll
        "focus",        // Triggered when an element gains focus
        "blur"          // Fires when an element loses focus
      ];


      events.forEach((event) => window.addEventListener(event, resetTimer));


      resetTimer(); // Start the timer

      // Cleanup on unmount or logout
      return () => {
        clearTimeout(timer);
        events.forEach((event) => window.removeEventListener(event, resetTimer));
      };
    };

    // Initialize the setup
    const unsubscribe = setupIdleTimeout();

    return () => {
      unsubscribe?.();
    };
  }, [timeout]);
};

export default useIdleLogout;

const initialState = {
    data: [],
    total: 1,
    params: {},
    allData: []
}

const fixingResponsibilityReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'GET_FIXING_RESPONSIBILITY_LIST':
            return {
                ...state,
                allData: action.allData,
                data: action.data,
                total: action.totalPages,
                params: action.params
            };

        case "CREATE_FIXING_RESPONSIBILITY":
            return {
                ...state,
            };

        case "UPDATE_FIXING_RESPONSIBILITY":
            return {
                ...state,
            };


        default:
            return { ...state }
    }
}
export default fixingResponsibilityReducer

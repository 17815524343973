const initialState = {
    data: [],
    total: 1,
    params: {},
    areaData: [],
    wardData: []
}

const rentDisbursementReducer = (state = initialState, action) => {
    switch (action.type) {

        case 'GET_RENT_DISBURSEMENT_DATA':
            return {
                ...state,
                allData: action.allData,
                data: action.data,
                total: action.totalPages,
                statusCount: action.statusCount,
                params: action.params
            }
        case 'GET_SLUM_DWELLER_DATA':
            return {
                ...state,
                applicationDetail: action.developerDetail,
                allSlumData: action.allData,
                slumData: action.data,
                totalSlum: action.totalPages,
                params: action.params
            }

        case 'GET_RENT_DISBURSEMENT_DATA_BY_ID':
            return {
                ...state,
                areaData: action.data
            }
        case 'GET_RENT_DISBURSEMENT_ACTIVE_AREA_DATA':
            return {
                ...state,
                areaData: action.data
            }
        case 'GET_RENT_DISBURSEMENT_ACTIVE_WARD_DATA':
            return {
                ...state,
                wardData: action.data
            }

        default:
            return { ...state }
    }
}
export default rentDisbursementReducer
